.nav-pagination {
  display: flex;
  padding: 10px;
}

.nav-pagination .left {
  justify-content: flex-start;
}
.nav-pagination .right {
  justify-content: flex-end;
}
.nav-pagination .center {
  justify-content: center;
}

.empty-list-container {
  flex-direction: column;
  min-height: 250px;
  height: 100%;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  text-align: center;
}
.flex-row {
  flex-direction: row;
}

.list-footer-root {
  padding: 10px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
}
.list-footer-loader-root {
  width: 100%;
  display: flex;
  color: rgba(0, 0, 0, 0.12);
  justify-content: center;
  padding: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
}
