.dialog-root .MuiDialogContent-root {
  background-color: black;
}
.dialog-title-root {
  font-size: 16px;
  color: #fff;
  background-color: grey;
  border: 3px solid blue;
}

.dialog-root .MuiInputAdornment-root > .MuiIconButton-root > .MuiSvgIcon-root {
  color: #000;
  margin-left: -13px;
}
