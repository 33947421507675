.avatar-root {
  cursor: pointer;
}
.primary {
  color: #020202;
  background-color: #fff;
}
.secondary {
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.6);
}
.amber {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f44336;
}
.brown {
  color: #ffffff;
  background-color: #795548;
}
.red {
  color: #ffffff;
  background-color: #f44336;
}
.green {
  color: #ffffff;
  background-color: #4caf50;
}
.blue {
  color: #ffffff;
  background-color: #2196f3;
}
.yellow {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffeb3b;
}
.grey {
  color: #ffffff;
  background-color: #9e9e9e;
}
.orange {
  color: #ff5722;
  background-color: #ff5722;
}
.purple {
  color: #9c27b0;
  background-color: #9c27b0;
}
.small {
  height: 40px;
  width: 40px;
}
.medium {
  height: 48px;
  width: 48px;
}
.large {
  height: 56px;
  width: 56px;
}
