@media screen and (max-width: 900px) {
  .in-build-app-sidebar {
    width: 300px;
  }
  .in-build-app-main-content {
    width: 100%;
  }
  .app-content-header {
    padding: 11px 30px 10px 30px;
  }
}

@media screen and (max-width: 1536px) {
  .in-build-app-sidebar {
    width: 340px;
  }
  .in-build-app-main-content {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .in-build-app-sidebar {
    width: 100%;
  }
  .in-build-app-main-content {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #2d2b2b;
    left: 0px;
    top: 0px;
    z-index: 3;
  }
  .chat-box-root .chat-box-title {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .back-btn {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .chat-box-title {
    font-size: 20px;
  }
}

@media screen and (min-width: 0px) {
  .title-root {
    font-size: 14px;
  }

  .star-icon-root {
    font-size: 18px;
  }
}
.in-build-app-container {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #2d2b2b;
  color: #fff;
  border-radius: 4;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  /*  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 1px rgba(0, 0, 0, 0.14);*/
}

.in-build-app-sidebar {
  padding: 10px;
  background-color: #171616;
  width: 360px;
  height: 100%;
  transition: all 0.3s ease;
  overflow: auto;
}
.in-build-app-main-content {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  background-color: 171616;
}
.app-content-header {
  background-color: #171616;
  padding: 11px 20px 10px 20px;
  border-bottom: 1px solid rgba(2, 2, 2, 0.1);
  display: flex;
  align-items: center;
}
.back-btn {
  margin-right: 10px;
  margin-left: -12px;
}

.tab-container {
  border-bottom: rgba(0, 0, 0, 0.06);
  background-color: #dbdbdb;
}
.tab-container .MuiSvgIcon-root {
  color: #000;
}
.tab-root {
  font-size: 12px;
  letter-spacing: 1.25px;
  min-width: 20px;
  width: 33.3%;
}

.chat-box-root {
  flex: 1;
  display: flex;
  overflow-y: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(2, 2, 2, 0.05);
}

.chat-box-title {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}
.MuiBox-root > .status-dot {
  position: relative;
  border-radius: 50%;
}

.app-content-header > .MuiBox-root .header-status-dot {
  right: 43px;
  bottom: 2px;
  position: relative;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.star-icon {
  font-size: 18px;
  margin-left: 8px;
  color: #f44336;
}

.perfect-scrollbar-chat-icon {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.star-icon-root {
  font-size: 18px;
}

.star-icon-root {
  display: block;
}
