.div-password-field .toggle-button-password > * {
  color: #000;
  border: none;
  margin-right: 10px;
}

.div-password-field
  .MuiBox-root
  .MuiInputAdornment-root
  .MuiIconButton-root
  .MuiSvgIcon-root {
  border: none;
  color: #000;
  width: 35px;
  height: 39px;
  margin-left: -10px;
}
