.dialog-root {
  position: relative;
}

.dialog-root:fullscreen {
  display: flex;
  flex-direction: column;
}

.media-viewer-root {
  position: relative;
  background-color: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.cancel-btn {
  color: #fff;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
}

.carousel-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel-root .slick-slide {
  text-align: center;
  position: relative;
}
.carousel-root .slick-slide img {
  width: auto !important;
  max-height: 96vh;
}
.carousel-root .slick-slide::before,
.carousel-root .slick-slide::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 30px;
  z-index: 1;
}

.carousel-root .slick-slide::before {
  top: 0px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.1));
}
.carousel-root .slick-slide::after {
  bottom: 0px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.95));
}
.carousel-root .slick-slide > * {
  position: relative;
  z-index: 9;
}
.carousel-root .slick-slide .slick-dots {
  bottom: 10px;
}
.carousel-root .slick-slide .slick-dots li button::before,
.carousel-root .slick-slide .slick-dots li.slick-active button::before {
  background-color: #fff;
}
.carousel-root .slick-slide .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0px;
  overflow: hidden;
}

.carousel-root .slick-slide::before {
  content: "";
  display: block;
  padding-top: 30%;
}
.carousel-root .slick-slide embed,
.carousel-root .slick-slide iframe,
.carousel-root .slick-slide object,
.carousel-root .slick-slide video {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0px;
}
