@media screen and (min-width: 1200px) {
  .chat-footer-root {
    padding-left: 30ox;
    padding-right: 30px;
  }
}
.chat-footer-root {
  position: sticky;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #171616;
  padding: 14px 20px 15px;
  display: flex;
  align-items: center;
  margin-top: auto;
  bottom: 0px;
}

.text-field-root {
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
}
.text-field-root .MuiOutlinedInput-multiline {
  padding: 10px 16px;
}
.text-field-root .MuiInputBase-root {
  background-color: rgba(255, 255, 255, 0.9);
}

.icon-btn-root {
  padding: 8px;
  color: rgba(255, 255, 255, 0.9);
}
