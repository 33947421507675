.no-records-root {
  color: #fff;
  background-color: rgba(2, 2, 2, 0.03);
  height: 100%;
}
.no-records-root-inner {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding-top: 50px;
}
