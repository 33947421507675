.carousel-root {
  display: flex;
  align-items: center;
  padding: 8px 66px 8px 18px;
  border-bottom: 1px solid #e0e0e0;
}
.btn-root {
  text-transform: capitalize;
  padding: 5px 8px;
  font-size: 16px;
  line-height: 1px;
}
.slider-root {
  position: relative;
}
.slider-root .top {
  padding-top: 25px;
}
.slider-root .top .slick-dots {
  bottom: auto;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 2;
}
.slider-root .top-left {
  padding-top: 25px;
}
.slider-root .top-left .slick-dots {
  width: auto;
  left: 0px;
  bottom: auto;
  right: auto;
  top: 0px;
  z-index: 2;
}

.slider-root .top-right {
  padding-top: 25px;
}
.slider-root .top-right .slick-dots {
  left: auto;
  width: auto;
  bottom: auto;
  right: 0px;
  top: 0px;
  z-index: 2;
}

.slider-root .bottom .slick-dots {
  left: 0;
  right: 0;
  z-index: 2;
}

.slider-root .bottom-left .slick-dots {
  left: 0;
  right: auto;
  width: auto;
  z-index: 2;
}

.slider-root .bottom-right .slick-dots {
  left: auto;
  width: auto;
  right: 0px;
  z-index: 2;
}
.slider-root .slick-prev {
  left: 20px;
  background-color: #7c7c7c;

  border: 2px solid #7c7c7c;
  z-index: 10;
}
.slider-root .slick-next {
  right: 20px;
  color: #7c7c7c;
}
.slider-root .slick-prev::before,
.slider-root .slick-next::before {
  color: rgba(0, 0, 0, 0.87);
}
.slider-root .slick-dots li,
.slider-root li button,
.slider-root li button::before {
  width: 10px;
  height: 10px;
  line-height: 1;
}
.slider-root li button::before {
  font-size: 0px;
  content: "";
  background-color: #7c7c7c;
  border-radius: 50%;
  box-sizing: border-box;
}
.slider-root li.slick-active button::before {
  background-color: #333333;
}

.slider-root .outline .slick-dots li button::before {
  background-color: transparent;
  border: 2px solid #7c7c7c;
}
.slider-root li.slick-active button::before {
  background-color: transparent;
  border-color: #333333;
}
