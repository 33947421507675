@media screen and (min-width: 1280px) {
  .chat-msg-item {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.chat-main-content {
  position: ;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.chat-main-content .MuiBox-root {
  overflow: auto;
  scroll-behavior: smooth;
}

.chat-msg-item {
  padding: 14px 20px;
  display: flex;
}

.chat-msg-item .chat-msg-content a {
  color: lightblue;
}

.sent-msg-item {
  justify-content: flex-end;
}

.chat-time {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.4px;
}

.sent-msg-item .chat-time {
  text-align: right;
  margin: 15px;
}

.chat-avatar {
  position: relative;
  margin-bottom: 4px;
}
.received-msg-item {
  align-items: flex-end;
}

.received-msg-type {
  position: sticky;
  padding-bottom: 120px;
  margin-bottom: 30px;
  align-items: center;
}

.received-msg-type .chat-avatar {
  margin-left: 10px;
}

.chat-msg-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.received-msg-type .chat-msg-content {
  margin-left: 10px;
}
.chat-bubble {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 1);
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px 8px 0 8px;
  max-width: 600px;
  font-size: 16px;
  margin-bottom: 8px;
}

.receive-bubble {
  background-color: #000;
  color: #fff;
  border: 8px 8px 8px 0;
}

.chat-bubble-img {
  margin: -16px;
}

.chat-bubble-img-row {
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  background-color: #fff;
  background-color: rgba(2, 2, 2, 0.02);
}
.chat-bubble-img-item {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}
.chat-bubble-img-item-inner {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  height: 100px;
}
.chat-bubble-img-item-inner img {
  object-fit: cover;
  display: block;
}
